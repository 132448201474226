

import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import BaseFiltrableDropdown from '@/components/Inputs/BaseFiltrableDropdown.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Lectura from '@/store/entities/OZONE/Franqueo/lectura';
import Producto from '@/store/entities/OZONE/Franqueo/producto';
import PeopleHeader from "@/components/People/people-header.vue";
import BaseFiltrableDropdown_oldVue from '@/components/Inputs/BaseFiltrableDropdown_old.vue';

@Component({components: {BaseInput, BaseFiltrableDropdown, PeopleHeader}})
export default class RegistroLecturas extends AbpBase{

title: String = this.L("Registro de lecturas");
lectura: Lectura = new Lectura();
disabled: Boolean = false;
precioReembolso: Number = 12;
precioAcuseRecibo: Number = 1.45;

showOptions: Boolean = true;
isFranqueoPagado: Boolean = false;
ambitosList = [];
tramosList = [];

// get clasificaciones(){
//   return null;
// }

// get clientes(){
//   return null;
// }

// get ambitos(){
//   return null;
// }

// get tramos(){
//   return null;
// }
get loading(){
  return this.$store.state.franqueo.loading;
}
get productos(){
  return this.$store.state.franqueo.productos;
}
get maquinas(){
  return this.$store.state.franqueo.maquinasList;
}

async created(){
  await this.getPage();
}

async getPage(){
  await this.$store.dispatch({
    type: 'franqueo/getProductos'
  })
}

async changeFranqueoType(e){
  console.log("checkd= " + e.target.value);
  await this.$store.dispatch({
    type: 'franqueo/getMaquinas'
  });
}

async changeProducto(value){
  console.log(value)
  let producto = this.productos.find(x => x.id == value);
  let tipoAmbitoId = producto.tipoAmbitoId;
  let tipoTramoId = producto.tipoTramoId;

  console.log(`Ambito: ${tipoAmbitoId} Tramo: ${tipoTramoId}`);
  await this.$store.dispatch({
      type: 'franqueo/getAmbitos',
      data: { tipoAmbitoId: tipoAmbitoId }
  }).then(value => {
      this.ambitosList = value;
  });

  await this.$store.dispatch({
      type: 'franqueo/getTramos',
      data: { tipoTramoId: tipoTramoId }
  }).then(value => {
      this.tramosList = value;
  });
}

async save(e) {
    e.preventDefault();
    // if (this.inputPais) {
    //   this.cliente.pais = this.inputPais.label;
    //   if (this.fileRecords.length > 0) {
    //     this.cliente.imagen = this.fileRecords[0].name();
    //     if (this.fileRecords[0].lastModified === 0) {
    //       this.cliente.fileContent = this.fileRecords[0].url;
    //     } else {
    //       this.cliente.fileContent = this.fileRecords[0].urlResized;
    //     }
    //   } else {
    //     this.cliente.imagen = null;
    //     this.cliente.fileContent = null;
    //   }
    //   if (this.editing) {
    //     await this.$store
    //       .dispatch({
    //         type: "cliente/update",
    //         data: this.cliente,
    //       })
    //       .then(() => this.close());
    //   } else {
    //     await this.$store
    //       .dispatch({
    //         type: "cliente/create",
    //         data: this.cliente,
    //       })
    //       .then(() => this.close());
    //   }
    // }
  }
  
  close(){
    this.resetFields();
    this.$router.go(-1);
  }

  resetFields(){
    this.lectura = new Lectura();
  }

franqueoRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    pais: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Pais")),
      placeholder: this.L("Pais"),
      trigger: "blur",
    },
  };
}
