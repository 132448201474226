import Entity from '../../entity'
import Maquina from './maquinas'
export default class Lectura extends Entity<number>{
    empleado: string;
    tipoFranqueo: number;
    maquinaId: number;
    clienteId: number;
    fechaDeposito: Date;
    oferta: string;
    producto: string;
    tramo: string;
    ambito: string;
    tarifa: number;
    IVA: number;
    sujetoAIVA: number;
    noSujetoAIVA: number;
    tarifaSinOpciones: number;
    contratoOp: string;
    numCliente: string;
    clasificacion: number;
    numEnvios: number;
    euros: number;
}